@import '../../styles/app';

/* Gradient color format for #28d456 */
$green-start: #28d456;
$green-end: #6bc992;
$gradient-color: linear-gradient(to right, $green-start, $green-end);

.widget {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  padding: $widget-padding-y $widget-padding-x;
  background: $widget-bg;
  height: auto; // Make height auto to allow content to dictate height
  max-width: 350px; // Maximum width
  overflow: hidden;
  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
  
  .iconContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    height: 60px;
  }

  img {
    max-width: 55px;
    max-height: 50px;
    z-index: 1;
    margin-right: 10px;
  }

  .titleContainer {
    z-index: 2;
    position: relative;
    margin-left: 5px;
    font-weight: bold; 
    
    .titleLink {
      color: inherit; // Default color
      text-decoration: none; // Remove underline
      
      &:hover {
        color: #5790c9; // Bluish link color on hover
      }
    }
  }

  .valueContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 90%;
    font-size: 1rem; // Base font-size
    margin-bottom: 1rem;
    white-space: pre-wrap;
    height: auto; // Make height auto
    position: relative;
    justify-content: flex-start;
  }

  // Responsive font size for the value
  @media (max-width: 768px) {
    .valueContainer {
      font-size: 0.9rem; // Decrease font size for smaller screens
    }
  }

  .tshLabel {
    font-size: 0.7rem;
    margin-right: 0.1rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .content {
    flex: 1;
  }

  .floatingCard {
    flex: 1; // Take up remaining space
    padding: 10px; // Add padding to make it float
    background-color: #ffffff; // White background color
    border-radius: 8px; // Rounded corners
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Box shadow
    display: flex;
    justify-content: space-between; // Align items horizontally
    align-items: flex-start; // Align items vertically at the start
    flex-wrap: wrap; // Allow items to wrap
  }

  .dateContainer {
    display: flex;
    flex-direction: column; // Stack items vertically
    align-items: center; // Center items horizontally
    margin-bottom: 10px; // Add space between date containers
  }

  .dateLabel {
    font-size: 0.7rem; // Base font size
    
    @media (max-width: 768px) {
      font-size: 0.6rem; // Decrease font size for smaller screens
    }
  }

  .createdOn {
    color: #28d456;  // Color for "CREATED ON"
  }

  .enddate {
    color: #5d80f9;  // Color for "END DATE"
  }

  .startDate,
  .endDate {
    font-size: 0.9rem; // Base font size
    
    @media (max-width: 768px) {
      font-size: 0.8rem; // Decrease font size for smaller screens
    }
  }

  .sharesContainer {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, 0.7);
  }
  
  .badge{
    border-radius: 2rem;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
  
    i.fa-trash-o {
      color: #e3716d; // Red color for trash icon
    }
  
    i.fa-pencil-square-o {
      color: #3754a5; // Blue color for edit icon
    }
  }

  .buttonsContainer i {
    font-size: 20px;
    margin: 0 5px;
    cursor: pointer;
  }
}
