
@import '../../styles/app';

/* Gradient color format for #28d456 */
$green-start: #28d456;
$green-end: #6bc992;
$gradient-color: linear-gradient(to right, $green-start, $green-end);

.widget {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  padding: $widget-padding-y $widget-padding-x;
  background: $widget-bg;
  height: 70px;
  overflow: hidden;
  
  // Box shadow added here
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out; // Optional: Add transition effect
  
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .iconWrapper {
    border-radius: 25%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .icon {
    font-size: 1.5rem;
    color: white;
    padding: 2px;
  }

  .titleContainer,
  .valueContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 30%;
    font-size: 0.9rem;
    white-space: pre-wrap;
    height: 40px;
  }

  .titleContainer {
    color: $widget-title-color;
    margin-top: 0.4rem;
    margin-bottom: 0.6rem;
  }

  .valueContainer {
    margin-left: auto;
    color: $widget-title-color;
    font-size: 1rem;
    white-space: nowrap;
    justify-content: space-between;  // Distribute space between TSH and value
    align-items: flex-end;  // Align items to the bottom
  }

  .tshLabel {
    font-size: 0.7rem;
    margin-left: auto;  // Push to the rightmost side
    margin-right: 0.1rem;
    color: rgba(0, 0, 0, 0.5); // Faint color with reduced opacity
  }

  .content {
    flex: 1;
  }
}





















