@import '../../styles/app';

.root {
  width: $sidebar-width-open;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $sidebar-bg-color;
  color: $sidebar-color;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: $navbar-height;
  height: 110px;
  width: 100%;
  background: 'white';

  a img {
    width: $sidebar-width-open - 60px;
  }
}

.companyName {
  font-size: 0.85em;
  font-weight: bold;
  color:#d1cfcf; /* or any other color you like */
  text-align: center;
  margin-bottom: 25px;
}

.nav {
  padding: 10px 0;
  overflow-y: auto;
}
.navWrapper {
  height: calc(100vh - 100px); /* Adjust height as needed */
  overflow-y: auto;
}

.divider {
  border-top: 1px solid rgba(128, 128, 128, 0.05); /* Fainter grey line */
  margin-top: 10px; /* Adjust this value to control the space between the header and the line */
  margin-bottom: 10px; /* Adjust this value to control the space between the line and the navigation content */
}

